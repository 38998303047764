jQuery(function () {
    var cls_topNavTrigger = 'js-top-nav-trigger';
    var cls_pageFadeActive = 'active';
    var cls_hasDropdown = 'js-has-dd';
    var cls_dropdown = 'js-dropdown';
    var cls_activeDropdown = 'active';
    var cls_activeSearch = 'active';
    var cls_activeNav = 'active';
    var cls_activeNavToggle = 'active';
    var cls_bodyFixed = 'cp-no-scroll';
    var cls_clamp = 'js-clamp';
    var pageFade = jQuery('#cpPageFade');
    var topNavTrigger = jQuery('.' + cls_topNavTrigger);
    var searchToggle = jQuery('#cpSearchToggle');
    var searchDropdown = jQuery('.js-search-top-dd');
    var searchInput = jQuery('.js-search-top-input');
    var topNavToggleButton = jQuery('#cpNavToggle');
    var navTop = jQuery('#cpNavTop');
    var body = jQuery('body');
    var resizeTimeout = 0;
    var mobileBreakpoint = 992;
    var flagOpen = false;

    var showPageFade = function () {
        if (!pageFade.hasClass(cls_pageFadeActive)) {
            pageFade.addClass(cls_pageFadeActive);
        }
    }

    var hidePageFade = function () {
        if (pageFade.hasClass(cls_pageFadeActive)) {
            pageFade.removeClass(cls_pageFadeActive);
        }
    }

    var hideMenu = function (el) {
        el.removeClass(cls_activeDropdown);
        el.parent().find('.' + cls_dropdown).removeClass(cls_activeDropdown);

    }

    var showMenu = function (el) {
        el.addClass(cls_activeDropdown);
        el.parent().find('.' + cls_dropdown).addClass(cls_activeDropdown);
    }

    var showMobileMenu = function () {
        navTop.addClass(cls_activeNav);
        body.addClass(cls_bodyFixed);
        topNavToggleButton.addClass(cls_activeNavToggle);
        flagOpen = true;
    }

    var hideMobileMenu = function () {
        navTop.removeClass(cls_activeNav);
        body.removeClass(cls_bodyFixed);
        topNavToggleButton.removeClass(cls_activeNavToggle);
        flagOpen = false;
    }

    var menuFixer = function () {
        var panel = document.querySelector('#cpFixPanel');
        var trigger = document.querySelector('#cpFixTrigger');
        var scroll = 0
        var prevScroll = 0;
        var triggerScroll = 0;
        var scrollDirection = 0;
        var translateDelay = null;
        var threshhold = 200;
        var classStuck = 'cp-fixed';
        var classShow = 'cp-show';
        var transClass = 'transition';
        if (!trigger) {
            return;
        }
        scroll = window.pageYOffset;
        triggerScroll = trigger.offsetTop;
        scrollDirection = scroll - prevScroll;

        window.addEventListener('scroll', function () {
            if (flagOpen) {
                return;
            }
            scroll = window.pageYOffset;
            triggerScroll = trigger.offsetTop + trigger.clientHeight + threshhold;
            scrollDirection = scroll - prevScroll;

            if (scrollDirection > 0) {
                scrollDirection = 1
            } else {
                scrollDirection = -1;
            }
            // console.log('Scroll: ', scroll);
            // console.log('TriggerScroll: ', triggerScroll);
            // console.log('direction: ', scrollDirection);
            if (scroll >= triggerScroll) {
                if (!$(panel).hasClass(classStuck)) {
                    $(panel).addClass(classStuck);
                    translateDelay = setTimeout(function () {
                        $(panel).addClass(transClass);
                        clearTimeout(translateDelay);
                        translateDelay = null;
                    }, 50);
                }
            } else {
                if ($(panel).hasClass(classStuck)) {
                    $(panel).removeClass(transClass).removeClass(classStuck);
                }
            }

            if (scroll > triggerScroll && scrollDirection < 0) {
                if (!$(panel).hasClass(classShow)) {
                    $(panel).addClass(classShow);
                }
            }
            if (scroll > triggerScroll && scrollDirection > 0) {
                if ($(panel).hasClass(classShow)) {
                    $(panel).removeClass(classShow);
                }
            }
            prevScroll = scroll;
        });
    }

    // var topNavListLeave = function () {
    //     topNavList.on('mouseleave', function () {
    //         hidePageFade();
    //     });
    // }

    // var topNavItemEnter = function () {
    //     topNavTrigger.on('mouseenter', function () {
    //         if (jQuery(this).hasClass(cls_hasDropdown)) {
    //             showPageFade();
    //         } else {
    //             hidePageFade();
    //         }
    //     });
    // }

    // var topNavItemLeave = function () {
    //     topNavTrigger.on('mouseleave', function (e) {
    //         var relTarget = e.relatedTarget;
    //         // if (relTarget !== jQuery(this).closest(topNavList).get(0)) {
    //         //     hidePageFade();
    //         // }
    //         hidePageFade();
    //     });
    // }

    var topNavItemClick = function () {
        topNavTrigger.on('click', function (e) {
            var el = jQuery(this);
            topNavTrigger.not(jQuery(this)).removeClass(cls_activeDropdown);
            jQuery('.' + cls_dropdown).removeClass(cls_activeDropdown);
            if (!el.hasClass(cls_hasDropdown)) {
                hidePageFade();
            }
            if (el.hasClass(cls_hasDropdown)) {
                e.preventDefault();
                if (el.hasClass(cls_activeDropdown)) {
                    hideMenu(el);
                    hidePageFade();
                } else {
                    showMenu(el);
                    searchDropdown.removeClass(cls_activeSearch);
                    showPageFade();
                }
            }
        });
    }

    var searchToggleClick = function () {
        searchToggle.on('click', function (e) {
            if (searchDropdown.hasClass(cls_activeSearch)) {
                searchDropdown.removeClass(cls_activeSearch);
                hidePageFade();
            } else {
                searchDropdown.addClass(cls_activeSearch);
                searchInput.trigger('focus');
                topNavTrigger.removeClass(cls_activeDropdown);
                jQuery('.' + cls_dropdown).removeClass(cls_activeDropdown);
                showPageFade();
            }
        });
    }

    var navToggleClick = function () {
        topNavToggleButton.on('click', function (e) {
            e.preventDefault();
            if (navTop.hasClass(cls_activeNav)) {
                hideMobileMenu();
            } else {
                showMobileMenu();
            }
        });
    }

    var mobileNavResizeWatcher = function (e) {
        clearTimeout(resizeTimeout);
        var currentWidth = window.innerWidth;
        if (currentWidth >= mobileBreakpoint && navTop.hasClass(cls_activeNav)) {
            resizeTimeout = setTimeout(function () {
                // >>> Hide mobile menu
                hideMobileMenu();
                hidePageFade();
                topNavToggleButton.removeClass(cls_activeNavToggle);
                // >>> Hide dropdowns
                topNavTrigger.removeClass(cls_activeDropdown);
                jQuery('.' + cls_dropdown).each(function () {
                    el = jQuery(this);
                    hideMenu(el);
                })
                clearTimeout(resizeTimeout);
            }, 100);
        }
    }

    pageFade.get(0).addEventListener('click', function (e) {
        var target = jQuery('.js-top-panel-nav').find(e.target);
        if (target.length) {
            // Clicked in box
            if (target.length) {
                searchDropdown.removeClass(cls_activeSearch);
            }
        } else {
            // Clicked outside the box
            topNavTrigger.removeClass(cls_activeDropdown);
            jQuery('.' + cls_dropdown).removeClass(cls_activeDropdown);
            if (!searchToggle.find(e.target).length && !searchDropdown.find(e.target).length) {
                searchDropdown.removeClass(cls_activeSearch);
                hidePageFade();
            }
        }
    });

    document.addEventListener('keyup', function (e) {
        if (e.key === 'Escape') {
            topNavTrigger.removeClass(cls_activeDropdown);
            jQuery('.' + cls_dropdown).removeClass(cls_activeDropdown);
            searchDropdown.removeClass(cls_activeSearch);
            hidePageFade();
        }
    });

    window.addEventListener('resize', function (e) {
        mobileNavResizeWatcher(e);
    });

    // topNavItemEnter();
    // topNavItemLeave();
    topNavItemClick();
    navToggleClick();
    searchToggleClick();
    // menuFixer();
    // topNavListLeave();
});